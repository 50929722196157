import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { IUpdateItem } from 'service/type';
import React, { useEffect, useState } from 'react';
import ModalCart from '../modal-cart';
import { useDispatch } from 'react-redux';
import OrderAction from '../../../../redux/actions/order/orderAction';
import { toast } from 'react-toastify';

function ItemCart(props) {
    const {
        item,
        booked,
        isBooked,
        hideShowIcon
    } = props;
    const pw = localStorage.getItem('PW');
    const onHandleClose = () => {
        setShowComfirm(false);
    };
    // ==========================================================================
    // STATE
    // ==========================================================================
    const [showComfirm, setShowComfirm] = useState(false);
    const [password, setPassword] = useState('');
    const [note, setNote] = useState('');
    const [selectedItem, setSelectedItem] = useState(0);
    const [selectedIdParent, setSelectIdParent] = useState(0);

    //============================================================================
    // DISPATCH
    // ===========================================================================
    const dispatch = useDispatch();
    const _updateBookedList = (detailId: number, qty: number, flag: string, noteStr: string, parentId: number) => dispatch<any>(OrderAction.updateBookedList({ detailId: detailId, qty: qty, flag: flag, note: noteStr, parent_id: parentId }));
    const _updateOrderList = (item_id: number, qty: number, flag: string, noteStr: string, parentId: number) => dispatch<any>(OrderAction.updateOrderList({ item_id: item_id, qty: qty, flag: flag, note: noteStr, parent_id: parentId }));
    const _removeItemsFromCart = (item_id: number, menu_id: number, parentId: number) => dispatch<any>(OrderAction.removeItemsFromCart({ item_id: item_id, menu_id: menu_id, parent_id: parentId }));
    const _removeItemBookedList = (detailId: number, parentId: number) => dispatch<any>(OrderAction.removeItemBookedList({ detailId: detailId, parent_id: parentId }));
    // =============================
    //  COMMON
    // =============================
    const formatMoney = (number) => {
        if (typeof number === 'number') {
            // Sử dụng toLocaleString để định dạng tiền tệ
            return number.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
        }
        return number;
    };
    // =============================
    // EVENTS HANDERS
    // =============================
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleConfirmDeleteItem = async (note) => {
        if (note === '') {
            toast.error('Vui lòng nhập note!', {
                position: 'top-right',
                theme: 'colored'
            });
        }
        else {
            _removeItemBookedList(selectedItem, selectedIdParent);
            setNote('');
            setPassword('');
            setShowComfirm(false);
            onHandleClose();
            toast.success('Xóa món thành công', {
                position: 'top-right',
                theme: 'colored'
            });
        }

    };
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleUpdateQuantity = async (body: IUpdateItem, type, item) => {
        if (isBooked) {
            if (body.qty < 1) {
                _updateBookedList(body.detailId, body.qty, 'DELETE', item.note, item.parent_id);
            }
            else {
                _updateBookedList(body.detailId, body.qty, 'UPDATE', item.note, item.parent_id);
            }
        }
        else {
            if (body.qty < 1) {
                // _updateOrderList(item.item_id,body.qty,'DELETE');
                _removeItemsFromCart(item.item_id, item.menu_id, item.parent_id);
            }
            else {
                _updateOrderList(item.item_id, body.qty, null, '', item.parent_id);
            }
        }
    };
    const handleChangeNote = (noteStr: string) => {
        setNote(noteStr);
        _updateBookedList(item.detailId, item.qty, 'UPDATE', noteStr, item.parent_id);
        _updateOrderList(item.item_id, item.qty, 'UPDATE', noteStr, item.parent_id);
    };
    const handleRemoveItem = async (cartItem: any) => {

        if (isBooked) {
            setSelectedItem(cartItem.detailId);
            setSelectIdParent(cartItem.parent_id);
            setShowComfirm(true);
        }
        else {
            _removeItemsFromCart(cartItem.item_id, cartItem.menu_id, item.parent_id);
        }
    };

    return (
        <div className="item">
            <div className="main-item">
                <div className="item-info">
                    <img src={item?.image || '../../../assets/icon/DefaultImageMeal/index.svg'} style={{ width: 50, height: 50 }} alt="can't load" />
                    <div>
                        <div className="font-big" style={{ marginBottom: 7, opacity: isBooked ? 0.6 : 1 }}>{item?.label || item?.itemName}</div>
                        <div className="font-normal">{formatMoney(item?.price)}</div>
                    </div>
                </div>
                <div className="item-value">
                    <div className='item-quantity' style={{ marginBottom: 10, justifyContent: hideShowIcon ? 'flex-end' : 'space-between' }}>
                        {!hideShowIcon ?
                            <div style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    handleUpdateQuantity({
                                        detailId: item.detailId,
                                        qty: item.qty - 1
                                    }, 'minus', isBooked ? item.detailId : item);
                                }}
                            >
                                <RemoveIcon fontSize='small'
                                    style={{
                                        border: '1.33px solid #CAA37D',
                                        borderRadius: 4
                                    }} />
                            </div> : null}

                        <span className='font-big'>{hideShowIcon ? 'X' : ''}{item.quantity || item?.qty}</span>

                        {!hideShowIcon ?
                            <div style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    handleUpdateQuantity({
                                        detailId: item.detailId,
                                        qty: item.qty + 1
                                    }, 'plus', item);
                                }}
                            >
                                <AddIcon fontSize='small'
                                    style={{
                                        background: '#CAA37D',
                                        color: 'white',
                                        borderRadius: 4
                                    }} />
                            </div> : null
                        }
                    </div>
                    <div className='font-big' style={{ textAlign: 'right' }}>{formatMoney(item?.price * item?.qty)}</div>
                </div>
            </div>

            <div className="note">
                <div className='note-label' style={{ marginTop: 5, opacity: booked ? 0.6 : 1 }}>
                    <NoteAltOutlinedIcon fontSize='small' />
                    Ghi chú:
                    <hr style={{ margin: 0 }}></hr>
                </div>
                <textarea rows={1}
                    className='font-normal note-style'
                    placeholder='no note'
                    value={item?.note || note}
                    onChange={(e) => handleChangeNote(e.target.value)}
                    defaultValue={item?.note} />
                {!hideShowIcon ? <div className='note-icon'>
                    <div onClick={() => {
                        handleRemoveItem(item);
                    }}>
                        <DeleteOutlineIcon fontSize='small' style={{ color: '#DC3545' }} />
                    </div>
                </div> : null}
            </div>
            <ModalCart
                show={showComfirm}
                onHandleClose={onHandleClose}
                onConfirm={handleConfirmDeleteItem}
                password={password}
                setPassword={setPassword}
                note={note}
                setNote={setNote}
            />
        </div>
    );

}


export default ItemCart;

