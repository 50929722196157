/* eslint-disable @typescript-eslint/no-shadow */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { fetchDish } from 'service/orderService';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalAction from '../../../redux/actions/global/globalAction';
import { useDispatch } from 'react-redux';
const Menu = (props, ref) => {
    const {
        chooseItemId,
        setChooseItemId,
        chooseCate,
        setChooseCate,
        chooseDetail,
        setChooseDetail,
        listMenu,
        setListMenu,
        setListMeal,
        setListSearch,
        currentLocation,
        setRecommendItemList,
        setParentMenuName
    } = props;
    const params = useParams();
    const [listAll, setListAll] = useState<any>([]);

    const [listDish, setListDish] = useState([]);
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const locationLocal = JSON.parse(localStorage.getItem('Location'));

    const hasChildren = (arr = []) => {
        return arr.some(item => item.children && item.children.length > 0);
    };

    const Menu = ({ item, cond, index }) => {
        const SubItem = item?.children && hasChildren(item?.children) ? item?.children : [];
        const dispatch = useDispatch();
        const _setItemMenu = (items) => dispatch<any>(GlobalAction.setItemMenu(items));
        return (
            <div className="sub-menu-scroll">
                {cond ? (
                    <div className="detail-cate" key={item?.menu_id}>
                        <div className="title-cate" onClick={() => {
                            _setItemMenu([]);
                            const newarr = chooseItemId.filter(i => i !== item?.menu_id);
                            setChooseItemId(newarr);
                        }}
                        >
                            <div>{item?.item_name}</div>
                            {
                                hasChildren(SubItem) ? (<div>
                                    <SVGChevronUp />
                                </div>) : null
                            }
                        </div>
                        {
                            SubItem?.length > 0
                                ? (
                                    <div>
                                        {SubItem.map((item: any, index: number) => {
                                            const DetailLable = item?.children && hasChildren(item?.children) ? item?.children : [];
                                            return (
                                                <div key={item?.menu_id}>
                                                    {
                                                        chooseCate.includes(item?.menu_id) ? (
                                                            <div key={item?.menu_id}>
                                                                <div className="title-sub-cate" onClick={() => {
                                                                    const newarrCate = chooseCate.filter(i => i !== item?.menu_id);
                                                                    setChooseCate(newarrCate);
                                                                }}
                                                                >
                                                                    <div>{item?.item_name}</div>
                                                                    {hasChildren(item?.children) ? <div><SVGMinus /></div> : null}
                                                                </div>
                                                                {
                                                                    DetailLable.length > 0 ? (
                                                                        <ul>
                                                                            {DetailLable?.map((ele: any, eleId: any) => {
                                                                                return (
                                                                                    <div key={ele?.menu_id} style={{ paddingTop: 5, paddingBottom: 5 }} onClick={() => {
                                                                                        console.log('ele', ele);
                                                                                        setListSearch(findAllLeafNodes(ele).filter(item => item.item_id !== 0));
                                                                                        setListMeal(findAllLeafNodes(ele).filter(item => item.item_id !== 0));
                                                                                    }}>
                                                                                        {
                                                                                            chooseDetail.includes(ele?.menu_id) ? (
                                                                                                <div className="subItemChoose" key={ele?.menu_id} >
                                                                                                    <li>{ele?.item_name}</li>
                                                                                                </div>
                                                                                            )
                                                                                                :
                                                                                                (
                                                                                                    <div className="subItemNotChoose"
                                                                                                        key={ele?.menu_id}
                                                                                                        onClick={() => setChooseDetail([ele?.menu_id])}
                                                                                                    >
                                                                                                        <li>{ele?.item_name}</li>
                                                                                                    </div>
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <div className="title-sub-cate" onClick={() => {
                                                                    setChooseCate([chooseCate, item?.menu_id]);
                                                                    setListSearch(findAllLeafNodes(item).filter(item => item.item_id !== 0));
                                                                    setListMeal(findAllLeafNodes(item).filter(item => item.item_id !== 0));
                                                                }}>
                                                                    <div>{item?.item_name}</div>
                                                                    {hasChildren(DetailLable) ? <div><SVGPlus /></div> : null}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null
                        }
                    </div>
                ) : (
                    <div>
                        <div className="title-cate-notchoose" onClick={() => {
                            _setItemMenu(item);
                            setChooseItemId([item?.menu_id]);
                            setListSearch(findAllLeafNodes(item).filter(item => item.item_id !== 0));
                            setListMeal(findAllLeafNodes(item).filter(item => item.item_id !== 0));
                            setParentMenuName(item?.item_name);
                            setRecommendItemList(listDish.filter(el => el.root_menu === item?.root_menu && el.parent_id === item?.menu_id && item.item_id === 0));
                        }}>
                            <div>{item?.item_name}</div>
                            {
                                hasChildren(SubItem) ? (
                                    <div>
                                        <SVGPlus />
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                )
                }
            </div >
        );
    };

    const convertToTree = (arr = []) => {
        const nodes = arr;
        const tree = (function (data, root) {
            const r = [];
            const o = {};

            data.forEach(function (a) {
                // Nếu o[a.menu_id] đã có children và a.item_id = 0, gán children đó cho a
                if (o[a.menu_id] && o[a.menu_id].children && a.item_id === 0) {
                    a.children = o[a.menu_id].children;
                }

                // Gán a cho o[a.menu_id] nếu item_id = 0
                if (a.item_id === 0) {
                    o[a.menu_id] = a;
                }

                if (a.parent_id === root) {
                    // Nếu a là root node, thêm a vào r
                    r.push(a);
                } else {
                    // Nếu o[a.parent_id] chưa tồn tại, tạo object mới
                    o[a.parent_id] = o[a.parent_id] || {};
                    o[a.parent_id].children = o[a.parent_id].children || [];

                    // Thêm a vào children của parent node nếu parent node có item_id = 0
                    if (o[a.parent_id].item_id === 0) {
                        o[a.parent_id].children.push(a);
                    }
                }
            });

            return r;
        })(nodes, 0); // Thay đổi giá trị root nếu cần

        return tree;
    };

    // console.log('list===', listMenu);
    const dispatch = useDispatch();
    const _setLoading = (loading: boolean) => dispatch<any>(GlobalAction.setLoading(loading));
    const handleFetchDish = async () => {
        _setLoading(true);
        const res = await fetchDish(locationLocal);
        if (res?.statusCode === 200) {
            setListDish(res?.metadata);
            setListMenu(convertToTree(res?.metadata));
            setListSearch(res?.metadata?.filter(item => item.item_id !== 0));
            setListAll(res?.metadata?.filter(item => item.item_id !== 0));
            setListMeal(res?.metadata?.filter(item => item.item_id !== 0));
            setParentMenuName('');
            setRecommendItemList(listMenu);
            _setLoading(false);
        }
        _setLoading(false);
    };

    useEffect(() => {
        handleFetchDish();
    }, []);

    useEffect(() => {
        if (chooseItemId?.length < 1) {
            setListSearch(listAll.filter(item => item.item_id !== 0));
            setListMeal(listAll.filter(item => item.item_id !== 0));
        }
    }, [chooseItemId, listAll, setListMeal, setListSearch]);

    const showMenu = () => {
        setOpen(true);
    };

    const hideMenu = () => {
        setOpen(false);
    };

    useImperativeHandle(ref, () => ({
        showMenu, hideMenu
    }));

    function findAllLeafNodes(obj, childrenKey = 'children') {
        let leafNodes = [];
        // Kiểm tra nếu node hiện tại không có children hoặc children rỗng
        if (!obj[childrenKey] || obj[childrenKey].length === 0 || obj.item_id) {
            leafNodes.push(obj);
        } else {
            // Duyệt qua tất cả các children và đệ quy tìm leaf nodes
            obj[childrenKey].forEach((child) => {
                leafNodes = leafNodes.concat(findAllLeafNodes(child, childrenKey));
            });
        }

        return leafNodes;
    }

    return (
        <>
            {open && <div style={{ height: 'calc(100vh - 64px)' }} className="menu-left">
                <div className="title-menu">
                    <SVGHome />
                    <div className="menu-name">Menu</div>
                </div>
                <div className="list-cate">
                    {listMenu && listMenu?.length > 0 && (<div className="cate">
                        {listMenu?.map((item, index) => <Menu key={item?.menu_id} item={item} cond={chooseItemId?.includes(item.menu_id)} index={index} />)}
                    </div>)}
                </div>
            </div>}
        </>
    );
};

export default forwardRef(Menu);

const SVGHome = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.13478 19.5014V16.4436C7.13478 15.6631 7.77217 15.0303 8.55844 15.0303H11.4326C11.8102 15.0303 12.1723 15.1792 12.4393 15.4443C12.7063 15.7093 12.8563 16.0688 12.8563 16.4436V19.5014C12.8539 19.8259 12.9821 20.1379 13.2124 20.3682C13.4427 20.5985 13.756 20.728 14.0829 20.728H16.0438C16.9596 20.7304 17.8388 20.3709 18.4872 19.7288C19.1356 19.0868 19.5 18.215 19.5 17.3059V8.59489C19.5 7.86049 19.1721 7.16387 18.6046 6.6927L11.934 1.4039C10.7737 0.476589 9.11111 0.506529 7.98539 1.47501L1.46701 6.6927C0.872741 7.14998 0.517552 7.84866 0.5 8.59489V17.297C0.5 19.1919 2.04738 20.728 3.95617 20.728H5.87229C6.55123 20.728 7.103 20.1842 7.10792 19.5103L7.13478 19.5014Z" fill="#0A0B0F" />
        </svg>
    );
};

const SVGPlus = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99984 2.93872C8.36803 2.93872 8.6665 3.2372 8.6665 3.60539V7.60539H12.6665C13.0347 7.60539 13.3332 7.90386 13.3332 8.27205C13.3332 8.64024 13.0347 8.93872 12.6665 8.93872H8.6665V12.9387C8.6665 13.3069 8.36803 13.6054 7.99984 13.6054C7.63165 13.6054 7.33317 13.3069 7.33317 12.9387V8.93872H3.33317C2.96498 8.93872 2.6665 8.64024 2.6665 8.27205C2.6665 7.90386 2.96498 7.60539 3.33317 7.60539H7.33317V3.60539C7.33317 3.2372 7.63165 2.93872 7.99984 2.93872Z" fill="#202223" />
        </svg>

    );
};
const SVGChevronUp = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 10.272L8 6.27197L4 10.272" stroke="#FDFDFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
const SVGMinus = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.3335 8.27197H12.6668" stroke="#CAA37D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};
