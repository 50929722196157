/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import ItemCart from './item-cart/index';
import DoneIcon from '@mui/icons-material/Done';
import { createNewOrder, fetchAreaCode, newUpdateItem, sendOrder, updateOrder } from 'service/orderService';
import FooterCart from './footer-cart';
import './styles.scss';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/system';
import { Tab } from '@mui/material';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import OrderAction from '../../../redux/actions/order/orderAction';
import GlobalAction from '../../../redux/actions/global/globalAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import printService from 'service/printService';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingTable from 'components/LoadingTable';

const Cart = (props, ref) => {
    const {
        setOpenCart,
        showModal,
        setShowModal,
        mealValue,
        forceFetchMeal,
        setForceFetchMeal,
        tabSelectDefault,
        onChangeTabsCard
    } = props;
    let navigate = useNavigate();
    let { id } = useParams();
    const [areaCode, setAreaCode] = useState();
    const [recallListBooked, setRecallListBooked] = useState<boolean>(false);
    // ===========================================================================
    // Selectors
    // ===========================================================================
    const {currentOrderId} =  useSelector((state: AppState) => state.currentOrderId);
    const {bookedList} = useSelector((state: AppState) => state.bookedList);
    const {orderList} = useSelector((state: AppState) => state.orderList);
    const {loc} = useSelector((state:AppState) => state.isLocal);
    const {currentTableId,currentnumChildren, currentnumElder} = useSelector((state:AppState) => state.global);
    const {currentLocation} = useSelector((state:AppState) => state.home);
    const userName = localStorage.getItem('Apec_Account');
    const password = localStorage.getItem('PW');
    const {loading} = useSelector((state:AppState) => state.global);
    const [show, setShow] = useState<boolean>(true);
    //============================================================================
    // Dispatch
    // ===========================================================================
    const dispatch = useDispatch();
    const _getBookedList = () => dispatch<any>(OrderAction.getBookedList(currentOrderId));
    const _setIsLocalValue = (value: string)  => dispatch<any>(OrderAction.setIsLocalValue(value));
    const _clearItemsOrderList = () => dispatch<any>(OrderAction.clearItemsOrderList());
    const _setLoading = (loading: boolean) => dispatch<any>(GlobalAction.setLoading(loading));
    const [tabSelected, settabSelected] = React.useState(tabSelectDefault);
    const _updateHeaderOrder = ()  => dispatch<any>(OrderAction.updateHeaderOrder());

    // ===========================================================================
    // Hooks
    // ===========================================================================
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        () =>
        {
            setForceFetchMeal(!forceFetchMeal);
            if(id === 'empty' || currentOrderId){
                _getBookedList();
            }
            else{
                navigate('/', { replace: true });
            }
        },[dispatch,currentOrderId,recallListBooked]);

    useEffect(() => {
        settabSelected(tabSelectDefault);
    }, [tabSelectDefault]);
        
    useEffect(() => {
        getAreaCode();
    }, [currentLocation]);

    useEffect(() =>{
        _clearItemsOrderList();
        setForceFetchMeal(!forceFetchMeal);
    },[]);

    const showCard = (index?: string) => {
        setShow(true);
        if(index) {
            settabSelected(index);
        }
    };


    const hideCard = () => {
        setShow(false);
        setOpenCart(false);
    };

    useImperativeHandle(ref, () => ({
        showCard, hideCard
    }));
    
    // ===========================================================================
    // Event Handlers
    // ===========================================================================
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        // onChangeTabsCard?.(newValue);
        settabSelected(newValue);
    };
    const formatBodyMeal = (arrOrder: any[]) => {
        let arr = [];
        arrOrder.map((item) => {
            arr.push({
                itemId: item.item_id | item.itemId,
                qty: item.quantity | item.qty,
                detailId: item.detailId ,
                flag: item.flag,
                note: item.note,
                menuId: item.root_menu
            });
        });
        return arr;
    };
    const getAreaCode = async () => {
        const res = await fetchAreaCode(currentLocation?.value || 'FR');
        if (res.statusCode === 200) {
            setAreaCode(res?.metadata[0]?.value);
        }
    };
    const handleUpdateOrder = async () => {
        const res = await updateOrder({
            orderId: parseInt(currentOrderId),
            locationCode: currentLocation?.value,
            areaCode: areaCode,
            tableId: parseInt(currentTableId),
            mealId: mealValue,
            noOfGuest: parseInt(currentnumElder) || 0,
            noOfChild: parseInt(currentnumChildren) || 0,
            note: '',
            isInhouse: loc,
            createdBy: JSON.parse(userName)?.username,
            salemanId: 0
        });
        if (res?.statusCode === 200) {
            // toast.success('Update order successfully', {
            //     position: 'top-right',
            //     theme: 'colored'
            // });
        } else {
            toast.error('Update order error', {
                position: 'top-right',
                theme: 'colored'
            });
        }
        setForceFetchMeal(!forceFetchMeal);
    };
    const handleSendOrder = async (id) => {
        const res = await sendOrder(id);
        if (res.statusCode === 200) {
            await printService(res.metadata[0]);
        }
        else{
            console.log(res.statusCode);
        }
    };
    const createOrder = async () =>{
        _setLoading(true);
        if(!currentOrderId){
            const res = await createNewOrder({
                locationCode: currentLocation?.value,
                areaCode: areaCode,
                tableId: parseInt(currentTableId),
                mealId: mealValue,
                noOfGuest: parseInt(currentnumElder) || 0,
                noOfChild: parseInt(currentnumChildren) || 0,
                note: '',
                isInhouse: loc,
                createdBy: JSON.parse(userName)?.username,
                salemanId: 0,
                items: formatBodyMeal(orderList)
            });
            if (res?.message === 'Success') {
                dispatch(OrderAction.setCurrentOrderNo(res?.metadata[0]?.order_no));
                dispatch(OrderAction.setCurrentOrderId(res?.metadata[0]?.order_id));
                settabSelected('2');
                // _updateHeaderOrder();
                // handleFetchOrder(true);
                handleSendOrder(res.metadata[0].order_id);
                toast.success('Create order successfully', {
                    position: 'top-right',
                    theme: 'colored'
                });
            } else {
                toast.error('Update order failed', {
                    position: 'top-right',
                    theme: 'colored'
                });
                console.log('create fail');
            }
            _setLoading(false);
        }
        else{
            handleUpdateOrder();
            const _orderList = formatBodyMeal(orderList);
            const _bookedList = formatBodyMeal(bookedList);
            const res = await newUpdateItem({
                orderId: parseInt(currentOrderId),
                password: password,
                createdBy: JSON.parse(userName)?.username,
                items: _orderList.concat(_bookedList)
            });
            if (res?.statusCode === 200) {
                // handleFetchOrder();
                handleSendOrder(currentOrderId);
                settabSelected('2');
                toast.success('Update order successfully', {
                    position: 'top-right',
                    theme: 'colored'
                });
                
            } else {
                toast.error('Add order failed', {
                    position: 'top-right',
                    theme: 'colored'
                });
                console.log('add more fail');
            }
            _setLoading(false);
        }
        _setLoading(false);
        setForceFetchMeal(!forceFetchMeal);
        _clearItemsOrderList();
        setRecallListBooked(!recallListBooked);
    };
    
    return (
        <>
            {show ? <div className="page-order-cart" style={{ width: 366, height: 'calc(100vh - 64px)' }}>
                <div className="content-order">
                    <div className="header">
                        <div className="first-header">
                            <div className="name-table"><SVGCart></SVGCart>
                                <div className="label">Current Order</div>
                            </div>
                            <div className="header-right-order" onClick={hideCard}>
                                <SVGClose />
                            </div>
                        </div>
                        <div className="header-below-order">
                            <div className="location" style={{
                                width: 100,
                                height: 28,
                                cursor:'pointer',
                                backgroundColor: loc === 'Y' ? '#CAA37D' : undefined,
                                color: loc === 'Y' ? '#FDFDFD' : undefined
                            }}
                            onClick={() => _setIsLocalValue('Y')}>
                                {loc === 'Y' && <DoneIcon fontSize='small' />} In House
                            </div>
                            <div className="meal" style={{
                                width: 65,
                                height: 28,
                                cursor:'pointer',
                                backgroundColor: loc === 'N' ? '#CAA37D' : undefined,
                                color: loc === 'N' ? '#FDFDFD' : undefined
                            }}
                            onClick={() => _setIsLocalValue('N')}>
                                {loc === 'N' && <DoneIcon fontSize='small' />} Local
                            </div>
                        </div>
                    </div>
                    {/*Content */}
                    <div className="content">
                        {loading && <LoadingTable/>}
                        <div className="item-list" style={{overflow:'inherit'}}>
                            <TabContext value={tabSelected}>
                                <Box sx={{ borderBottom: 1, borderColor:'#CBCBCD' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" TabIndicatorProps={{sx: {background:'#DC8108'}}}>
                                        <Tab label="Các món đã  chọn" value="1" style={{textTransform:'none'}}/>
                                        <Tab label="Các món đã gọi" value="2" style={{textTransform:'none'}}/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1" style={{overflowY:'auto',width:'100%', display: 'flex',alignItems: 'flex-start', flexDirection: 'column', flex: 1}}>
                                    {orderList.length > 0  ? orderList?.map((item, index) => (
                                        <ItemCart
                                            key={index}
                                            item={item}
                                            booked
                                            isBooked={false}
                                        />
                                    )):
                                        (<div style={{ flex: 1, display: 'flex', margin: 'auto', height: '100%' }}>
                                            <div className="empty-list">
                                                <WorkOffOutlinedIcon/>
                                                <div className="title-empty">Giỏ hàng đang trống</div>
                                            </div>
                                        </div>)
                                    }
                                </TabPanel>
                                <TabPanel value="2" style={{overflowY:'auto',width:'100%', display: 'flex',alignItems: 'flex-start', flexDirection: 'column', flex: 1}}>
                                    {bookedList.filter(item => item.flag !== 'DELETE' ).length > 0  ? bookedList.filter(item => item.flag !== 'DELETE' )?.map((item, index) => (
                                        <ItemCart
                                            key={index}
                                            item={item}
                                            booked
                                            isBooked={true}
                                            showModal={showModal}
                                            setShowModal={setShowModal}
                                            bookedList={bookedList}
                                            hideShowIcon
                                        />
                                    ))
                                        :(
                                            <div style={{ flex: 1, display: 'flex', margin: 'auto', height: '100%' }}>
                                                <div className="empty-list">
                                                    <WorkOffOutlinedIcon/>
                                                    <div className="title-empty">Giỏ hàng đang trống</div>
                                                </div>
                                            </div>
                                        )}
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                    <FooterCart
                        createOrder={createOrder}
                        bookedList={bookedList}
                        orderList={orderList}
                    />
                </div>
            </div> : <></>
            }
        </>
    );
};
export default forwardRef(Cart);

const SVGCart = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1213 11.9611H16.8891C17.3088 11.9611 17.6386 11.6142 17.6386 11.1958C17.6386 10.7672 17.3088 10.4304 16.8891 10.4304H14.1213C13.7016 10.4304 13.3719 10.7672 13.3719 11.1958C13.3719 11.6142 13.7016 11.9611 14.1213 11.9611ZM20.1766 6.65552C20.7861 6.65552 21.1858 6.86983 21.5855 7.33926C21.9852 7.8087 22.0551 8.48223 21.9652 9.09352L21.0159 15.7881C20.8361 17.0749 19.7569 18.023 18.4879 18.023H7.58639C6.25742 18.023 5.15828 16.9831 5.04837 15.636L4.12908 4.51143L2.62026 4.2461C2.22057 4.17466 1.94079 3.77666 2.01073 3.36846C2.08068 2.95107 2.47038 2.67451 2.88006 2.73677L5.2632 3.10313C5.60293 3.16538 5.85274 3.4501 5.88272 3.79707L6.07257 6.08301C6.10254 6.4106 6.36234 6.65552 6.68209 6.65552H20.1766ZM7.42631 19.6359C6.58697 19.6359 5.9075 20.3298 5.9075 21.1871C5.9075 22.0341 6.58697 22.728 7.42631 22.728C8.25567 22.728 8.93514 22.0341 8.93514 21.1871C8.93514 20.3298 8.25567 19.6359 7.42631 19.6359ZM18.6676 19.6359C17.8282 19.6359 17.1487 20.3298 17.1487 21.1871C17.1487 22.0341 17.8282 22.728 18.6676 22.728C19.4969 22.728 20.1764 22.0341 20.1764 21.1871C20.1764 20.3298 19.4969 19.6359 18.6676 19.6359Z" fill="#0A0B0F" />
        </svg>
    );
};
const SVGClose = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.4312 2.4182C15.7156 2.13383 15.7153 1.65485 15.4196 1.35921L14.3689 0.308485C14.0606 0.000204638 13.5992 0.00765056 13.3099 0.296877L7.99999 5.60682L2.69005 0.296877C2.40568 0.0125126 1.9267 0.0128476 1.63106 0.308485L0.580335 1.35921C0.272055 1.66749 0.279501 2.12897 0.568728 2.4182L5.87867 7.72814L0.568728 13.0381C0.284363 13.3224 0.284698 13.8014 0.580335 14.0971L1.63106 15.1478C1.93934 15.4561 2.40082 15.4486 2.69005 15.1594L7.99999 9.84946L13.3099 15.1594C13.5943 15.4438 14.0733 15.4434 14.3689 15.1478L15.4196 14.0971C15.7279 13.7888 15.7205 13.3273 15.4312 13.0381L10.1213 7.72814L15.4312 2.4182Z" fill="#595959" />
        </svg>
    );
};
