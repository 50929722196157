/* eslint-disable import/named */
// import { connect } from 'react-redux'
// eslint-disable-next-line import/named
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import { SVGArrowBottom, SVGDown, SVGTable } from 'assets/svg';
import DropDownComponent from 'components/DropDown';
import TableComponent from 'components/Table';
import { useNavigate } from 'react-router-dom';
import { fetchArea, fetchListOrderUnPaid, fetchLocation, fetchTableMenu } from 'service/orderService';
import { OrderPaidModel, TableModel } from 'models/order';
import { TableDropDown } from 'components/DropDown/types';
import { Language } from 'shared/Language';
import { MyTextInput } from 'components/Input';
import { TextFieldActions } from 'components/Input/types';
import IcSearch from '../../assets/svg/ic_search.svg';
import { TYPE_INPUT } from 'constants/AppEnums';
import LoadingTable from 'components/LoadingTable';
import DatePickerDay from 'components/DatePickerDay';
import HeaderOrder from 'layout/header/header-order';
import { arrKey, columns, FlexRow, styleRow } from 'assets/static-data/payment';
import { TypeTable } from 'constants/Defines';
import Format from 'utils/Format';
import { connect, useDispatch, useSelector } from 'react-redux';
import GlobalAction from '../../redux/actions/global/globalAction';
import DateUtils from 'utils/DateUtils';
import { AppState } from 'redux/store';
import { changeCurrentLocations, getLocations, getTables } from 'redux/actions/home/homeAction';
import { toast } from 'react-toastify';

type ParamsSearch = {
    table?: string;
    date?: string;
    text?: string;
    location?: string
}

function PaymentComponent(props) {
    const { getLocations, changeCurrentLocations, currentLocation } = props;
    const [listTableName, setListTableName] = useState<TableDropDown[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<OrderPaidModel[]>();

    const [params, setParams] = useState<ParamsSearch>({
        table: '',
        date: DateUtils.formatMMDDYYYYPicker(new Date()),
        text: '',
        location: ''
    });
    const [location, setLocation] = useState<any>([]);
    const [currentVT, setCurrentVT] = useState<string>('');
    const locationLocal = JSON.parse(localStorage.getItem('Location'));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const refInput = useRef<TextFieldActions>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showTableList = (_location) => {
        localStorage.setItem('Location', JSON.stringify(_location.value));
        onFetchArea(_location.value);
        handleClose();
        setCurrentVT(_location.text);
    };

    useEffect(() => {
        // getListOderByTable('', DateUtils.formatMMDDYYYYPicker(new Date()), locationLocal);
        onFetchLocation();
    }, []);

    const onFetchLocation = async () => {
        fetchLocation().then((res) => {
            getLocations(res.metadata);
            setLocation(res.metadata);
            if (res.metadata && res.metadata.length > 0) {
                onFetchArea(locationLocal);
                setCurrentVT(res?.metadata.find(item => item?.value === locationLocal).text);
                changeCurrentLocations(res.metadata.find(item => item.value === locationLocal));
            }
        });
    };

    const onFetchArea = async (value) => {
        fetchArea(value).then((res) => {
            if (res.metadata && res.metadata.length > 0) {
                setParams(last => {
                    last.location = res.metadata[0]?.value;
                    getListTable(res.metadata[0]?.value);
                    return last;
                });
                getListOderByTable(params.table, params.date, value);
            }
        });
    };

    const getListOderByTable = useCallback(async (table: string, date: string, local?: string) => {
        setLoading(true);
        const res = await fetchListOrderUnPaid(table, date, encodeURIComponent(refInput.current.getValue().toUpperCase()) || '', local || locationLocal);
        setLoading(false);
        if (res.message === 'Success') {
            const _data = res.metadata as OrderPaidModel[];
            setData(_data);
        }
    }, [locationLocal]);

    const getListTable = useCallback(async (value?: string) => {
        setLoading(true);
        const res = await fetchTableMenu(value || params.location);
        setLoading(false);

        if (res.message === 'Success') {
            const _data = res.metadata as TableModel[];
            const tableName = [] as TableDropDown[];
            _data?.filter(i => i.type !== TypeTable.COT && i.type !== TypeTable.CUA).map((item: TableModel) => {
                if (tableName.length > 0 && !tableName.find(e => e.value === item.table_name)) {
                    tableName.push({ id: item.table_id, value: item.table_name });
                }
                if (tableName.length === 0) {
                    tableName.push({ id: item.table_id, value: item.table_name });
                }
            });
            setListTableName(tableName);
        } else {
            toast.error(res?.message || Language.err.notFound, {
                theme: 'colored'
            });
        }
    }, []);

    const onChooseTable = (item: TableDropDown) => {
        setParams(last => {
            last.table = item.value;
            getListOderByTable(item.value, last.date);
            return last;
        });
    };

    const onNavigateDetail = (item: OrderPaidModel) => {
        const tabLeId = listTableName.find(i => i.value === item.table_code);
        if (tabLeId) {
            dispatch(GlobalAction.changeCurrentTables(tabLeId.id));
            dispatch(GlobalAction.changeCurrentTablesName(tabLeId?.value));
        }
        navigate('/payment-detail', { state: item });
    };


    const onChooseDate = (date: string) => {
        setParams(last => {
            last.date = date;
            getListOderByTable(last.table, last.date);
            return last;
        });
    };

    return (
        <div className='page-payment-view'>
            <HeaderOrder
                label={`${Language.payment.selectPayment}`}
                styleLabel={'text-big-x bold color-black mgr-5'}
            />
            <div className='page-payment-container'>
                <div className='jus-between flex-row'>
                    <DropDownComponent
                        label={Language.orderPaid.table}
                        stylesContainer='btn border'
                        styleLabel='drop-label'
                        styleIconRight={'payment-icon-right'}
                        iconLeft={<SVGTable />}
                        iconRight={<SVGDown />}
                        widthMenu={200}
                        onPress={onChooseTable}
                        data={Format.sortUpTable(listTableName)}
                        stylesDrops={'width-input'}
                        isClose
                    />
                    <DatePickerDay onPress={onChooseDate} day={new Date()} />

                    <div className='dropdown-location-payment'>
                        <div className='btn-dropdown' onClick={handleClick}>
                            <SVGLocation />
                            <div className='drop-label text-location' >{currentVT}</div>
                            <SVGArrowBottom />
                        </div>
                        <div className='list-dropdown-item'>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button'
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {location?.map((e, index) => (
                                    <MenuItem onClick={() => showTableList(e)} key={index}>
                                        {e.text}
                                    </MenuItem>
                                ))}
                            </StyledMenu>
                        </div>
                    </div>
                    <MyTextInput
                        rightIcon={IcSearch}
                        type={TYPE_INPUT.TEXT}
                        ref={refInput}
                        value={''}
                        containerInput={'input border'}
                        inputStyle={'input-style'}
                        placeHolder={Language.orderPaid.placeHolder}
                        onClickRightIcon={() => getListOderByTable(params.table, params.date)}
                    />
                </div>
                <TableComponent
                    data={data}
                    columns={columns}
                    onPress={onNavigateDetail}
                    styles='table-payment-view'
                    keyTable={arrKey}
                    flex={FlexRow}
                    labelIcon={'Payment'}
                    styleRow={styleRow}
                    nameIcon={'right'}
                />
            </div>

            {isLoading && <LoadingTable />}
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        locations: state.home.locations,
        tables: state.home.tables,
        currentLocation: state.home.currentLocation
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLocations: (payload) => {
            dispatch(getLocations(payload));
        },
        getTables: (payload) => {
            dispatch(getTables(payload));
        },
        changeCurrentLocations: (payload) => {
            dispatch(changeCurrentLocations(payload));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null)(PaymentComponent);

const SVGLocation = () => {
    return (
        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.14673 14.8929C6.09448 14.9641 6.02621 15.0219 5.94745 15.0618C5.86868 15.1017 5.78164 15.1225 5.69336 15.1225C5.60508 15.1225 5.51803 15.1017 5.43927 15.0618C5.36051 15.0219 5.29224 14.9641 5.23998 14.8929C2.16761 10.7136 0.630859 7.72894 0.630859 5.94019C0.630859 3.12769 2.88086 0.877686 5.69336 0.877686C8.50586 0.877686 10.7559 3.12769 10.7559 5.94019C10.7559 7.72894 9.21911 10.7136 6.14673 14.8929ZM5.69336 8.19019C6.2901 8.19019 6.86239 7.95313 7.28435 7.53118C7.70631 7.10922 7.94336 6.53692 7.94336 5.94019C7.94336 5.34345 7.70631 4.77115 7.28435 4.3492C6.86239 3.92724 6.2901 3.69019 5.69336 3.69019C5.09662 3.69019 4.52433 3.92724 4.10237 4.3492C3.68041 4.77115 3.44336 5.34345 3.44336 5.94019C3.44336 6.53692 3.68041 7.10922 4.10237 7.53118C4.52433 7.95313 5.09662 8.19019 5.69336 8.19019Z" fill="#333333" />
        </svg>
    );
};

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 294,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                )
            }
        }
    }
}));
