/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import HeaderOrder from 'layout/header/header-order';
import Dish from './dish';
import Menu from 'pages/order/menu';
import SearchIcon from '@mui/icons-material/Search';
import Cart from './cart';
import { SVGCard, SVGClose, SVGMenu, SVGMerge, SVGPayment, SVGCould, SVGDinner, SVGLunch } from 'assets/svg';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { Language } from 'shared/Language';
import { TableModel } from 'models/order';
import { TableDropDown } from 'components/DropDown/types';
import { AppState } from 'redux/store';
import { fetchMeal } from 'service/orderService';
import { checkTimeNowBetween } from 'utils/common';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalAction from '../../redux/actions/global/globalAction';
import PoupSplitBill from '../splitorder/popup-split-bill/index';
import { toast } from 'react-toastify';
import { TypeTable } from 'constants/Defines';
import LoadingTable from 'components/LoadingTable';
import { SET_IS_LOCAL } from 'redux/constants/orderConstants';
export const Order = (props) => {
    const { currentLocation, tables } = props;
    const [openMenu, setOpenMenu] = useState(false);
    const [openCart, setOpenCart] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [chooseItemId, setChooseItemId] = useState();
    const [chooseCate, setChooseCate] = useState([]);
    const [chooseDetail, setChooseDetail] = useState([]);
    const [listTable, setListTableName] = useState<TableDropDown[]>();
    const [listMenu, setListMenu] = useState<any>([]);
    const [listMeal, setListMeal] = useState([]);
    const [listSearch, setListSearch] = useState([]);
    const [showSplitModal, setShowSplitModal] = useState(false);
    const [mealValue, setMealValue] = useState('');
    const [mealName, setmealName] = useState('');
    const [iconButton, setIconButton] = useState(<SVGLunch />);
    const [styleBtnRight, setStylebtnRight] = useState('');
    const [recommendItemList, setRecommendItemList] = useState([]);
    const [parentMenuName, setParentMenuName] = useState('');
    const [activeItem, setActiveItem] = useState(0);
    const [forceFetchMeal, setForceFetchMeal] = useState(false);
    const [tabSelectDefault, setTabSelectDefault] = useState<string>('1');
    const [arrTime, setArrTime] = useState([]);
    const refMenu = useRef(null);
    const refCard = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        refCard.current?.hideCard();
        const tableName = [] as TableDropDown[];
        tables?.filter(i => i.type !== TypeTable.COT && i.type !== TypeTable.CUA).map((item: TableModel) => tableName.push({ id: item.table_id, value: item.table_name }));
        setListTableName(tableName);

    }, [tables]);
    const { currentOrderId } = useSelector(
        (state: AppState) => state.currentOrderId);
    const { currentOrderNo } = useSelector(
        (state: AppState) => state.currentOrderNo);

    const { currentTableName } = useSelector(
        (state: AppState) => state.global);
    const { itemMenu } = useSelector(
        (state: AppState) => state.global);
    useEffect(() => {
        if (currentOrderId) {
            setTabSelectDefault('2');
            setOpenCart(true);
            refCard.current?.showCard();
        } else {
            setTabSelectDefault('1');
            dispatch({ type: SET_IS_LOCAL, payload: 'Y' });
        }
    }, [currentOrderId]);

    useEffect(
        () => onFetchMeal(currentLocation?.value)
        , [currentLocation, forceFetchMeal]
    );

    useEffect(() => {
        if (!currentTableName) {
            navigate('/');
        }
    }, [currentTableName]);

    // ===========================================================================
    // Selectors
    // ===========================================================================
    const { orderList } = useSelector((state: AppState) => state.orderList);


    // ==================
    // Dispatch
    // ==================
    const dispatch = useDispatch();
    const _setLoading = (loading: boolean) => dispatch<any>(GlobalAction.setLoading(loading));
    const { loading } = useSelector((state: AppState) => state.global);

    const onFetchMeal = (location: string) => {
        _setLoading(true);
        if (location) {
            fetchMeal(location).then(
                (res) => {
                    if (res.statusCode === 200 && res?.metadata?.length > 0) {
                        const arr = res.metadata.sort((a, b) => {
                            const time1 = Number(a.fromtime.split(':')[0]); // ignore upper and lowercase
                            const time2 = Number(b.fromtime.split(':')[0]); // ignore upper and lowercase
                            if (time1 < time2) {
                                return -1;
                            }
                            if (time1 > time2) {
                                return 1;
                            }

                            return 0;
                        });
                        for (let index = 0; index < arr.length; index++) {
                            const element = arr[index];
                            if (checkTimeNowBetween(element.fromtime, element.totime)) {
                                setMealValue(element.value);

                                // switch(new Date().getHours()){
                                //     case 6:
                                //         setmealName('Breakfast');
                                //         setIconButton(<SVGCould/>);
                                //         setStylebtnRight('bg-breakfast');
                                //         break;
                                //     case 10:
                                //         setmealName('Lunch');
                                //         setIconButton(<SVGLunch/>);
                                //         setStylebtnRight('bg-lunch');
                                //         break;
                                //     case 14:
                                //         setmealName('Dinner');
                                //         setIconButton(<SVGDinner/>);
                                //         setStylebtnRight('bg-dinner');
                                //         break;
                                //     default:
                                //         setmealName('Breakfast');
                                //         setIconButton(<SVGCould/>);
                                //         setStylebtnRight('bg-breakfast');
                                //         break;
                                // }
                                // break;

                            }
                        }
                        if (Number(new Date().getHours()) < Number(arr[1].fromtime.split(':')[0])) {
                            setmealName('Breakfast');
                            setIconButton(<SVGCould />);
                            setStylebtnRight('bg-breakfast');
                        } else if (Number(new Date().getHours()) >= Number(arr[1].fromtime.split(':')[0]) && Number(new Date().getHours()) < Number(arr[2].fromtime.split(':')[0])) {
                            setmealName('Lunch');
                            setIconButton(<SVGLunch />);
                            setStylebtnRight('bg-lunch');
                        } else {
                            setmealName('Dinner');
                            setIconButton(<SVGDinner />);
                            setStylebtnRight('bg-dinner');
                        }

                    } else {
                        setmealName('Breakfast');
                        setIconButton(<SVGCould />);
                        setStylebtnRight('bg-breakfast');
                    }
                    _setLoading(false);
                }
            );
        }
    };
    // ===========================================================================
    // Selectors
    // ===========================================================================


    const RenderMenu = () => {
        return (
            <Menu
                ref={refMenu}
                open={openMenu}
                setOpen={setOpenMenu}
                chooseItemId={chooseItemId}
                setChooseItemId={setChooseItemId}
                chooseCate={chooseCate}
                setChooseCate={setChooseCate}
                chooseDetail={chooseDetail}
                setChooseDetail={setChooseDetail}
                listMenu={listMenu}
                listMeal={listMeal}
                setListMenu={setListMenu}
                setListMeal={setListMeal}
                listSearch={listSearch}
                setListSearch={setListSearch}
                currentLocation={currentLocation}
                setRecommendItemList={setRecommendItemList}
                setParentMenuName={setParentMenuName}
            />
        );
    };
    const returnWithDish = () => {
        if (!openMenu && openCart) {
            return '366px';
        }
        if (openMenu && !openCart) {
            return '244px';
        }
        if (!openMenu && !openCart) {
            return '0px';
        }
        if (openMenu && openCart) {
            return '610px';
        }
    };
    function findAllLeafNodes(obj, childrenKey = 'children') {
        let leafNodes = [];
        if (!obj[childrenKey] || obj[childrenKey].length === 0 || obj.item_id) {
            leafNodes.push(obj);
            return leafNodes;
        }

        obj[childrenKey].forEach((child) => {
            leafNodes = leafNodes.concat(findAllLeafNodes(child, childrenKey));
        });

        return leafNodes;
    }

    const onNavigateMerge = () => {
        if (currentOrderId) {
            navigate('/merge', { replace: true });
        } else {
            toast.error(Language.err.orderIdErr, {
                position: 'top-right',
                theme: 'colored'
            });
        }
    };

    const onNavigatePayment = () => {
        if (currentOrderId && currentTableName) {
            navigate('/payment-detail', { state: { order_id: currentOrderId, table_code: currentTableName, order_no: currentOrderNo } });
        } else {
            toast.error(Language.err.orderIdErr, {
                position: 'top-right',
                theme: 'colored'
            });
        }
    };


    const onShowModalSlip = () => {
        if (currentOrderId) {
            setShowSplitModal(!showSplitModal);
        } else {
            toast.error(Language.err.orderIdErr, {
                position: 'top-right',
                theme: 'colored'
            });
        }
    };

    const onChangeTabsCard = (value: string) => {
        setTabSelectDefault(value);
    };

    const onCard = () => {
        setOpenCart(true);
        setOpenMenu(false);
        refMenu.current?.hideMenu();
        refCard.current?.showCard();
    };

    const onSelectItem = () => {
        setOpenCart(true);
        setOpenMenu(false);
        refMenu.current?.hideMenu();
        refCard.current?.showCard('1');
    };

    const onNavigateOrderId = (id) => {
        navigate(`/order/${id}`);
    };
    return (
        <React.Fragment>
            <HeaderOrder
                tables={listTable}
                label={`${Language.orderPaid.order}:`}
                styleLabel={'text-big-x bold color-black mgr-5'}
                id={currentOrderNo}
                labelButtonRight={mealName || 'Breakfast'}
                iconButton={iconButton}
                stylesButtonRight={`button-right-header ${styleBtnRight}`}
                defaultTable={currentTableName}
                onChangeId={onNavigateOrderId}
            />
            <div className='page-order'>
                <div className='page-order-menu' style={{ width: openMenu ? 244 : 0 }}>
                    <div className={!openMenu ? 'menu-content2' : 'menu-content1'}>
                        {RenderMenu()}
                    </div>
                </div>
                <div
                    className="page-order-dish flex-col"
                    style={{
                        width: `calc(100% - ${returnWithDish()})`
                    }}
                >
                    <div className="header-menu">
                        <div className="content-left flex flex-1" >
                            <div
                                className="d-flex action-menu align-items-center"
                                onClick={() => {
                                    if (!openMenu) {
                                        refCard.current?.hideCard();
                                    }
                                    refMenu.current?.showMenu();
                                    setOpenMenu(!openMenu);
                                    setOpenCart(false);
                                }}
                            >
                                {openMenu ? <SVGClose /> : <SVGMenu />}
                                <div className="label ml-3"> {!openMenu ? 'Menu' : 'Menu'}: {parentMenuName}</div>
                            </div>
                        </div>
                        <div className="content-right" style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
                            <div className="search" style={{ height: 44 }}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Tìm kiếm"
                                    inputProps={{ 'aria-label': 'Tìm kiếm' }}
                                    onChange={(event) => setKeyword(event.target.value)}
                                />
                                <IconButton
                                    type="button"
                                    sx={{ p: '10px' }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </div>
                            {!openCart && <Button
                                className='open-card-btn' style={{ color: '#fdfdfd', background: '#E54959', textTransform: 'none', borderRadius: 8, marginRight: 15 }} onClick={onCard}> <SVGCard />  Cart ({orderList.length || 0})</Button>}
                        </div>
                    </div>
                    <div className='tagItems flex'>
                        <div
                            className='itemlabel text_7A7A7B text_size_14'
                            style={{
                                whiteSpace: 'nowrap',
                                background: activeItem === 0 ? 'rgb(202, 163, 125)' : '#fff',
                                color: activeItem === 0 ? '#fff' : '#7A7A7B'
                            }}
                            onClick={() => {
                                if (recommendItemList?.length > 0 && itemMenu?.length !== 0) {
                                    setListSearch(findAllLeafNodes(itemMenu));
                                    setListMeal(findAllLeafNodes(itemMenu));
                                    setActiveItem(0);
                                }
                            }}
                        >All</div>
                        {
                            recommendItemList
                            && recommendItemList.map(item => {

                                return (
                                    <div
                                        key={item.menu_id}
                                        className='itemlabel text_7A7A7B text_size_14'
                                        style={{
                                            whiteSpace: 'nowrap',
                                            background: activeItem === item.menu_id ? 'rgb(202, 163, 125)' : '#fff',
                                            color: activeItem === item.menu_id ? '#fff' : '#7A7A7B'
                                        }}
                                        onClick={() => {
                                            setChooseCate([chooseCate, item?.menu_id]);
                                            setListSearch(findAllLeafNodes(item));
                                            setListMeal(findAllLeafNodes(item));
                                            setActiveItem(item.menu_id);
                                        }}
                                    >
                                        {item.item_name}

                                    </div>
                                );
                            })
                        }
                        {/* {recommendItemList && <div className='itemlabel text_7A7A7B text_size_14' style={{whiteSpace: 'nowrap'}}>Empty</div>} */}
                    </div>
                    <Dish
                        open={openMenu}
                        keyword={keyword}
                        setOpen={setOpenMenu}
                        listMeal={listMeal}
                        listSearch={listSearch}
                        setListSearch={setListSearch}
                        setTabs={onChangeTabsCard}
                        showCard={onSelectItem}
                    />
                    {/* <div  className='button-order-bottom'>
                        <Button variant="contained" onClick={onShowModalSlip} style={{textTransform:'none',flex:1, background:'#2F549F',padding:10, borderTopLeftRadius:4, borderBottomLeftRadius:4}}><SVGMerge/> Split Bill</Button>
                        <Button variant="contained" onClick={onNavigateMerge} style={{textTransform:'none',flex:1, background:'#19975F'}}><SVGMerge/> Merge Bill</Button>
                        <Button variant="contained" style={{textTransform:'none',flex:1, background:'#FFA731', borderTopRightRadius:4, borderBottomRightRadius:4}} onClick={onNavigatePayment}><SVGPayment/>Payment</Button>
                    </div> */}
                </div>
                <Cart
                    ref={refCard}
                    setOpenCart={setOpenCart}
                    mealValue={mealValue}
                    setForceFetchMeal={setForceFetchMeal}
                    forceFetchMeal={forceFetchMeal}
                    tabSelectDefault={tabSelectDefault}
                    onChangeTabsCard={onChangeTabsCard}
                />
            </div>
            {loading && <LoadingTable />}
            <PoupSplitBill showModal={showSplitModal} setShow={setShowSplitModal} />
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        currentLocation: state.home.currentLocation,
        tables: state.home.tables
    };
};

export default connect(mapStateToProps, null, null)(Order);
