import React, { useState, useEffect, useMemo, useCallback, useRef, useLayoutEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import Header from 'layout/header';
import { fetchArea, fetchLocation, fetchOrder, fetchTableMenu, fetchUpdateTable } from 'service/orderService';
import { changeCurrentLocations, getLocations, getTables } from 'redux/actions/home/homeAction';
import moment from 'moment';
import PopupChooseTable from './popup-choose-table/PopupChooseTable';
import { useNavigate } from 'react-router-dom';
import { TAB_HOME } from 'constants/AppEnums';
import OrderAction from '../../redux/actions/order/orderAction';
import GlobalAction from '../../redux/actions/global/globalAction';
import Flow from 'pages/flow';
import LoadingTable from 'components/LoadingTable';
import { toast } from 'react-toastify';
import { Language } from 'shared/Language';
import { AppState } from 'redux/store';
import { TypeTable } from 'constants/Defines';
import Format from 'utils/Format';
import BgEmpty from 'assets/img/img_empty.png';

export const Home = (props) => {
    let navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { getLocations, locations, getTables, changeCurrentLocations, currentLocation, tables } = props;
    const [tab, setTab] = useState(TAB_HOME.TABLE);
    const [ordersByTable, setOrdersByTable] = useState([]);
    const [isModalChooseTable, setIsModalChooseTable] = useState(false);
    const [nodeFlow, setNodeFlow] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [area, setArea] = useState<string>('');
    const dispatch = useDispatch();
    const refTable = useRef<any>(null);

    const [currentTable, setCurrentable] = useState();
    const [size, setSize] = useState({
        width: 0,
        height: 0
    });
    const { maxX, maxY, minX, minY, isEdit } = useSelector(
        (state: AppState) => state.common);

    const { currentTableId } = useSelector(
        (state: AppState) => state.global);
    const local = localStorage.getItem('Location');
    const locationLocal = local !== 'undefined' ? JSON.parse(local) : 'undefined';

    const [loadLocation, setLoadLocation] = useState<boolean>(false);
    const editFlow = useRef(false);

    // const {currentOrderId} = useSelector((state: AppState) => state.currentOrderId);

    // const getWindowDimensions = () => {
    //     const { innerWidth: width, innerHeight: height } = window;
    //     return {
    //         width,
    //         height
    //     };
    // };

    useEffect(() => {
        dispatch(OrderAction.setEdit(false));
        setSize(last => {
            last.width = refTable.current.offsetWidth;
            last.height = refTable.current.offsetHeight;
            return last;
        });
    }, [dispatch]);

    useEffect(() => {
        setTableFlow();
    }, [dispatch, tables]);



    const setTableFlow = () => {
        if (tables?.length === 0) return setLoadLocation(false);
        if (tables && tables.length > 0) {
            const nodes = [];
            tables?.map((item) => {

                nodes.push({
                    id: item.table_id,
                    type: (item?.type !== TypeTable.COT || item?.type !== TypeTable.CUA && item?.type === null) ? TypeTable.BAN : item?.type,
                    position: {
                        x: Number(item?.left) || 0,
                        y: Number(item?.top) || 0
                    },
                    data: {
                        numberChair: Number(item?.noOfSeat),
                        isActive: false,
                        name: 'Ban 4',
                        value: item?.table_name,
                        orderNo: item?.order_no,
                        rotate: item?.direction
                    },
                    sourcePosition: 'bottom',
                    targetPosition: 'top'
                });
            });
            dispatch(OrderAction.setFlow(nodes));

            const _maxX = Math.max(...nodes.map(item => Number(item.position.x)));
            const _maxY = Math.max(...nodes.map(item => Number(item.position.y)));
            const _minX = Math.min(...nodes.map(item => Number(item.position.x)));
            const _minY = Math.min(...nodes.map(item => Number(item.position.y)));
            dispatch(OrderAction.setMaxX(_maxX));
            dispatch(OrderAction.setMaxY(_maxY));
            dispatch(OrderAction.setMinX(_minX === 0 ? -1 : _minX));
            dispatch(OrderAction.setMinY(_minY === 0 ? -1 : _minY - 20));
            // dispatch(OrderAction.setMaxX(_maxX));
            // dispatch(OrderAction.setMaxY(_maxY));
            // dispatch(OrderAction.setMinX(_minX));
            // dispatch(OrderAction.setMinY(_minY));

            setNodeFlow(nodes);
            setLoadLocation(false);
        }
    };

    useEffect(() => {
        onFetchLocation();
        dispatch(OrderAction.setCurrentOrderNo(''));
        dispatch(OrderAction.setCurrentOrderId(''));
    }, []);

    const onFetchOrder = (tableId) => {
        fetchOrder(tableId).then((res) => {
            setOrdersByTable(res.metadata);
        });
    };

    const onFetchLocation = async () => {
        fetchLocation().then((res) => {
            getLocations(res.metadata);
            if (res.metadata && res.metadata.length > 0) {

                if (locationLocal === 'undefined') {
                    changeCurrentLocations(res.metadata[0]);
                    onFetchArea(res.metadata[0].value);
                    localStorage.setItem('Location', JSON.stringify(res.metadata[0].value));
                } else {
                    onFetchArea(locationLocal);
                    changeCurrentLocations(res.metadata.find(item => item.value === locationLocal));
                }
            } else {
                getTables([]);
                toast.error(res?.message || Language.err.notFound, {
                    theme: 'colored'
                });
            }
        });
    };
    const onFetchArea = useCallback(
        async (value) => {
            setLoading(true);
            setLoadLocation(true);
            if (value) {
                fetchArea(value).then((res) => {
                    setLoading(false);
                    if (res.metadata && res.metadata.length > 0) {
                        setArea(res.metadata[0].value);
                        onFetchTableMenu(res.metadata[0].value);
                    } else {
                        getTables([]);
                        toast.error(Language.err.notFound, {
                            theme: 'colored'
                        });
                        setLoadLocation(false);
                    }
                }).catch(() => setLoading(false));
            }
        }, []);

    const onFetchTableMenu = async (value) => {
        fetchTableMenu(value).then((res) => {
            getTables(res.metadata);
        }).catch((err) => {
            getTables([]);
        }
        );

    };

    const onSaveTable = useCallback(async (params) => {
        if (params && params.length > 0) {
            setLoading(true);
            const _params = params.filter(item => item?.id !== null);
            const res = await fetchUpdateTable(_params);
            setLoading(false);
            if (res.message === 'Success') {
                toast.success(Language.success.updateSuccess, {
                    position: 'top-right',
                    theme: 'colored'
                });
                dispatch(OrderAction.setEdit(false));
                editFlow.current = false;
            }
        } else {
            toast.error(Language.err.errParamsTableNull, {
                position: 'top-right',
                theme: 'colored'
            });
        }
    }, [dispatch]);

    const convertTable = (table) => {
        const _table = [];
        table?.map(item => _table.push({ id: item?.table_id, value: item?.table_code }));
        return _table;
    };

    const renderEmpty = () => {
        return (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={BgEmpty} style={{ width: 200, height: 200 }} />
                    <div className='text-normal color-black regular'>{Language.err.empty}</div>
                </div>
            </div>
        );
    };

    const renderViewTable = useMemo(() => {
        const onSelectTable = (value) => {

            if (!editFlow.current) {
                if (value.table_code === TypeTable.COT || value.table_code === TypeTable.CUA) return;
                if (value.order_no) {
                    dispatch(GlobalAction.changeCurrentTables(value?.table_id));
                    // dispatch(GlobalAction.changeCurrentTablesName(value?.data?.value));
                    setCurrentable(value?.table_id);
                    dispatch(GlobalAction.changeCurrentTablesName(value?.table_name));
                    onFetchOrder(value?.table_id);
                    setIsModalChooseTable(true);
                } else {
                    dispatch(GlobalAction.changeCurrentTables(value?.table_id));
                    dispatch(GlobalAction.changeCurrentTablesName(value?.table_name));
                    dispatch(OrderAction.setCurrentOrderNo(''));
                    dispatch(OrderAction.setCurrentOrderId(''));
                    navigate('/order/empty');
                }
            }
        };

        const onEdit = async () => {
            editFlow.current = !isEdit;
            if (!editFlow.current) {
                onFetchArea(locationLocal);
            }
        };

        return (
            <>
                <div className='page-home' ref={refTable}>
                    {tables && !loadLocation ?
                        <>
                            {tables.length > 0 ? <Flow size={size} table={tables} onSelectTable={onSelectTable} nodeFlow={nodeFlow} onSave={onSaveTable} onEdit={onEdit} /> : renderEmpty()}
                        </> : <LoadingTable />}
                </div>
                {
                    isModalChooseTable
                    && <PopupChooseTable
                        tables={Format.sortUpTable(convertTable(tables).filter(e => e.id !== currentTable))}
                        isModalChooseTable={isModalChooseTable}
                        ordersByTable={ordersByTable}
                        onFetchArea={onFetchArea}
                        currentLocation={currentLocation}
                        // currentTable={currentTable}
                        onHidden={() => {
                            setIsModalChooseTable(false);
                        }}
                    />
                }

            </>
        );
    }, [currentLocation, currentTable, dispatch, isEdit, isModalChooseTable, loadLocation, navigate, nodeFlow, onFetchArea, onSaveTable, ordersByTable, size, tables, locationLocal]);


    return (
        <>
            <Header setTab={setTab} tab={tab} currentLocation={currentLocation} locations={locations} changeCurrentLocations={changeCurrentLocations} onFetchArea={onFetchArea}></Header>
            {renderViewTable}
            {loading && <LoadingTable />}
        </>
    );
};
const mapStateToProps = (state, ownProps) => {
    return {
        locations: state.home.locations,
        tables: state.home.tables,
        currentLocation: state.home.currentLocation,
        currentOrderNo: state.home.currentOrderNo
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLocations: (payload) => {
            dispatch(getLocations(payload));
        },
        getTables: (payload) => {
            dispatch(getTables(payload));
        },
        changeCurrentLocations: (payload) => {
            dispatch(changeCurrentLocations(payload));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null)(Home);
